<template>
  <div class="login">
    <v-card class="my-auto" max-width="480px">
      <v-card-subtitle class="text-center">
        <h1 class="primary--text text-center my-6">
          App Comprovação Rádio
        </h1>
        <span>
          Informe seu Login e Senha para acessar o App
        </span>
      </v-card-subtitle>

      <v-card-text>
        <v-form
          class="card-form"
          ref="loginForm"
          v-model="isValid"
          lazy-validation
        >
          <v-text-field
            v-model="email"
            :rules="emailRules"
            color="primary"
            label="E-mail"
            required
            @keydown="errorFeedBack = false"
          ></v-text-field>

          <v-text-field
            v-model="pass"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passRules"
            :type="showPass ? 'text' : 'password'"
            name="input-10-2"
            label="Senha"
            color="primary"
            class="input-group--focused"
            @click:append="showPass = !showPass"
            @keyup.enter="doLogin"
            @keydown="errorFeedBack = false"
          ></v-text-field>

          <v-alert dense outlined type="error" v-if="errorFeedBack">
            Email e/ou Senha <strong>incorretos</strong>!
          </v-alert>

          <router-link class="primary--text body-2" :to="{ name: '' }">
            Esqueci a senha
          </router-link>

          <v-card-actions>
            <v-btn
              class="btn-primary mt-4 "
              color="primary"
              block
              dark
              :loading="btnLoading"
              @click="doLogin()"
            >
              Entrar
            </v-btn>
          </v-card-actions>

          <v-card-text class="actions text-center">
            <div>
              Ainda não tem cadastro?
              <router-link
                class="primary--text body-2"
                :to="{ name: 'Register' }"
              >
                Clique e registre-se.
              </router-link>
            </div>
          </v-card-text>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { auth, signInWithEmailAndPassword } from "@/firebase/firebase";

export default {
  name: "Login",

  data: () => ({
    show: false,
    isValid: true,
    email: "",
    emailRules: [
      (v) => !!v || "O E-mail é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],
    pass: "",
    passRules: [(v) => !!v || "A senha é obrigatória"],
    showPass: false,
    errorFeedBack: false,
    btnLoading: false,
  }),

  mounted() {
    if (auth.currentUser) this.$router.push({ name: "Home" });
  },

  methods: {
    doLogin() {
      if (this.$refs.loginForm.validate()) {
        this.btnLoading = true;
        signInWithEmailAndPassword(auth, this.email, this.pass)
          .then((userCredential) => {
            this.$store.dispatch("loadCurrentUser", userCredential.user.uid);
            this.$router.push({ path: "/" });
          })
          .catch((error) => {
            console.log(error.code, error.message);
            this.errorFeedBack = true;
            this.resetFormPassField();
          });
      }
    },
    resetFormPassField() {
      this.btnLoading = false;
      this.pass = null;
      this.$refs.loginForm.resetValidation();
    },
  },
};
</script>

<style>
.login {
  display: flex;
  justify-content: center;
  height: 90vh;
}
</style>
